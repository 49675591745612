import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createMetaManager } from 'vue-meta'
import VueGtag from "vue-gtag";

loadFonts()

const app = createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(createMetaManager())
    .use(VueGtag, { config: { id: "G-T0BFE4M7E1" } })
    
app.mount('#app')
