<template>
    <div class="image-container">
        <v-img class="svg" :src="mySVG"></v-img>
    </div>
</template>

<script>
    export default{
        data(){
            return {
              mySVG: require('../assets/KRYPTEX_Logo.svg')
            }
        },
        name: 'business-image',
    }
</script>

<style>
.image-container {
    width: 100%;
    height: 300px;
    display: flex;
    justify-self: center;
    align-content: center;
    justify-content: center;
    animation-duration: 0.5s;
    animation-name: slidein;
}

.svg {

    animation: pulse 3s alternate infinite;
}

@keyframes pulse {
  from {
    scale: 0.9;
  }

  to {
    scale: 1;
  }
}

@keyframes slidein {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}
</style>