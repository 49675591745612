<template>
  <v-app>
    <app-bar/>
    <v-main>
      <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `KRYPTEX IT-Solutions` }}</template>
      </metainfo>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { useMeta } from 'vue-meta'
import appBar from '@/components/app-bar.vue'

export default {
  setup () {
    useMeta({
      title: 'KRYPTEX IT-Solutions - Maßgeschneiderte Softwarelösungen',
      description: 'Softwareentwicklung - UI/UX-Design - IT-Sicherheitsberatung',
      htmlAttrs: { lang: 'de' }
    })
  },
  name: 'App',
  components: {
    appBar
  },

  data: () => ({
    //
  }),
}
</script>
