<template>
    <div class="text-container text-center justify-center align-center">
        <h1 class="mt-5">Software Erfolgreich und Effizient mit unserem Team</h1>
        <p>
            Sie suchen nach einer erfahrenen und zuverlässigen Softwareentwicklungsfirma, die Ihre Geschäftsprozesse automatisieren und Ihre digitale Präsenz stärken kann? Dann sind Sie bei uns genau richtig!
        </p>
        <p>
            Unser Team aus erfahrenen Entwicklern hat bereits zahlreiche erfolgreiche Projekte für Unternehmen aus verschiedenen Branchen durchgeführt. Wir verstehen die Bedürfnisse unserer Kunden und setzen modernste Technologien und Tools ein, um maßgeschneiderte Lösungen zu entwickeln, die perfekt auf ihre Anforderungen abgestimmt sind.
        </p>
        <p>
            Wir bieten eine breite Palette von Softwareentwicklungsdienstleistungen an, einschließlich der Entwicklung von Webanwendungen, mobilen Anwendungen, Cloud-Lösungen und vielem mehr. Wir arbeiten eng mit unseren Kunden zusammen, um ihre Anforderungen zu verstehen und sicherzustellen, dass das Endprodukt ihren Erwartungen entspricht.
        </p>
        <p>
            Unsere Entwickler haben ein tiefes Verständnis von Technologien wie Vue.js, .NET, SQL und vieles mehr. Wir setzen agile Methoden und DevOps-Praktiken ein, um sicherzustellen, dass unsere Kunden das bestmögliche Produkt erhalten, das auf dem neuesten Stand der Technik ist.
        </p>
        <p>
            Kontaktieren Sie uns noch heute, um mehr darüber zu erfahren, wie wir Ihnen bei Ihrer nächsten Softwareentwicklung helfen können. Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten und Ihr Unternehmen auf die nächste Stufe zu bringen.
        </p>
    </div>
</template>

<script>
    import { useMeta } from 'vue-meta'
    export default{
        setup () {
            useMeta({
            title: 'Softwareentwicklung',
            description: 'Wir entwickeln Ihre Softwarelösungen mit höchsten Qualitätsstandards.',
            htmlAttrs: { lang: 'de' }
            })
        },
    }
</script>

<style scoped>
    .text-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 10%;
    }
    h1 {
        font-weight: 200;
        width: 80%;
        text-transform: uppercase;
        margin-bottom: 1%;
    }
    p {
        width: 80%;
        line-height: 1.5rem;
		font-size: 18px;
        margin-top: 20px;
	}
</style>