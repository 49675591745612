<template>
    <div class="contact">
        <h1 class="title">Wir sind für Sie da</h1>
        <h2 class="subtitle">Sie können uns telefonisch oder per E-Mail erreichen</h2>
        <div class="info mt-5">
            <v-icon size="small">mdi-phone</v-icon>
            <h2 class="ml-5 text">01573/7564017</h2>
        </div>
        <div class="info mt-5">
            <v-icon size="small">mdi-email</v-icon>
            <h2 class="ml-5 text">info@kryptex-it.de</h2>
        </div>
        <!-- <contact-formular/> -->
    </div>
</template>

<script>
    import { useMeta } from 'vue-meta'
    // import ContactFormular from '@/components/contact-formular.vue'
    export default {
        setup () {
            useMeta({
            title: 'Kontakt',
            description: 'Kontaktieren Sie uns noch heute per Telefon oder E-Mail.',
            htmlAttrs: { lang: 'de' }
            })
        },
        // components: {
        //     ContactFormular
        // }
    }
</script>

<style scoped>
    .contact{
        margin-top: 2%;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title {
        font-weight: 200;
        font-size: 64px;
    }

    .subtitle {
        font-weight: 200;
        font-size: 24px;
    }

    .info {
        white-space: nowrap;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: row !important;
    }

    @media screen and (max-width: 720px) {
        .title {
            font-size: 20pt;
        }
        .subtitle {
            font-weight: 200;
            font-size: 12pt;
        }
        .text{
            font-size: 12pt;
        }
    }
</style>