<template>
    <v-app-bar height="50" class="bg-background bar" flat>
        <!-- <div v-show="checkPath" class="back">
            <v-btn icon @click="$router.push('/')"><v-icon>mdi-arrow-left</v-icon></v-btn>
        </div> -->
        <div class="actions">
        <v-btn to="/kontakt" class="text-primary">
            Kontakt
        </v-btn>
        <!-- <v-btn to="/ueber-uns" class="text-primary">
            Über uns
        </v-btn> -->
        <v-img @click="goToLandingPage" class="logo" max-height="40" max-width="40" src="..\assets\KRYPTEX_Logo.svg">

        </v-img>
        <v-btn to="/impressum" class="text-primary">
            Impressum
        </v-btn>
        </div>
    </v-app-bar>
</template>

<script>
    export default{
        name: 'app-bar',
        computed: {
            checkPath(){
                return this.$route.path != "/";
            }
        },
        methods:{
            goToLandingPage() {
                this.$router.push("/");
            }
        }
    }
</script>

<style>
    .back{ 
        float: left;
    }
    .actions{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }
    .bar{
        opacity: 0.95;
        justify-content: space-between;
    }

    .logo{
        cursor: pointer;
    }

    .v-toolbar__content{
        align-content: center;
        align-items: center;
        justify-items: center;

    }
</style>